import gql from 'graphql-tag';

import { PersonFragment } from './PersonFragment.fragment';

export const ContentOwnerHistoryQuery = gql`
	query ContentOwnerHistoryQuery($contentId: ID!) {
		singleContent(id: $contentId) {
			id
			history {
				createdDate
				createdBy {
					...PersonFragment
				}
				ownedBy {
					...PersonFragment
				}
			}
		}
	}
	${PersonFragment}
`;
