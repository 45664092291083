import gql from 'graphql-tag';

export const SpaceAdminsListQuery = gql`
	query SpaceAdminsListQuery($spaceKey: String!) {
		space(key: $spaceKey) {
			id
			admins(accountType: ATLASSIAN) {
				type
				... on KnownUser {
					email
				}
				... on User {
					email
				}
			}
		}
	}
`;
