import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { usePageSpaceKey } from '@confluence/page-context';

import { SpaceAdminsListQuery } from './queries/SpaceAdminsListQuery.graphql';
import type {
	SpaceAdminsListQuery as SpaceAdminsListQueryType,
	SpaceAdminsListQueryVariables,
	SpaceAdminsListQuery_space_admins as SpaceAdminType,
} from './queries/__types__/SpaceAdminsListQuery';

type SpaceAdminsType = SpaceAdminType | null;

export const useContentOwnershipSpaceAdmins = () => {
	const [spaceKey] = usePageSpaceKey();
	const [spaceAdminsList, setSpaceAdminsList] = useState<SpaceAdminsType[]>([]);

	const { data } = useQuery<SpaceAdminsListQueryType, SpaceAdminsListQueryVariables>(
		SpaceAdminsListQuery,
		{
			variables: {
				spaceKey: spaceKey!,
			},
			skip: !spaceKey,
		},
	);

	useEffect(() => {
		const spaceAdmins = data?.space?.admins || [];
		setSpaceAdminsList(spaceAdmins);
	}, [data]);

	return spaceAdminsList;
};
