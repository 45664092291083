import gql from 'graphql-tag';

export const SpaceAdminQuery = gql`
	query SpaceAdminQuery($spaceKey: String!) {
		space(key: $spaceKey) {
			id
			currentUser {
				isAdmin
			}
		}
	}
`;
