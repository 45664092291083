import gql from 'graphql-tag';

export const ContentOwnershipContentInfoQuery = gql`
	query ContentOwnershipContentInfoQuery($contentId: ID!) {
		singleContent(id: $contentId) {
			id
			title
			status
		}
	}
`;
