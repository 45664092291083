import gql from 'graphql-tag';

import { PersonFragment } from './PersonFragment.fragment';

export const ContentOwnershipContributorsQuery = gql`
	query ContentOwnershipContributorsQuery($contentId: ID!, $limit: Int = 4) {
		content(id: $contentId) {
			nodes {
				id
				history {
					ownedBy {
						...PersonFragment
					}
				}
				version {
					by {
						...PersonFragment
					}
				}
			}
		}
		contentContributors(id: $contentId, limit: $limit) {
			nodes {
				...PersonFragment
			}
		}
	}

	${PersonFragment}
`;
