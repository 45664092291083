import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { N0, N10, N200, N800, Y50 } from '@atlaskit/theme/colors';
import { fontFallback } from '@atlaskit/theme/typography';

import { borderRadius } from '@confluence/typography-placeholder';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const PermissionWarningContainer = styled.div({
	display: 'flex',
	background: `${token('color.background.warning', Y50)}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${borderRadius}`,
	padding: token('space.200', '16px'),
	marginTop: token('space.200', '16px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Text = styled.p({
	margin: `${token('space.300', '24px')} 0px ${token(
		'space.300',
		'24px',
	)} ${token('space.200', '16px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TextStrong = styled.p({
	marginLeft: token('space.200', '16px'),
	fontWeight: '700',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FlexColumn = styled.div({
	flexDirection: 'column',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	button: {
		paddingLeft: token('space.0', '0px'),
		marginLeft: token('space.200', '16px'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'button:hover': {
		padding: token('space.0', '0px'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'button span': {
		marginLeft: token('space.0', '0px'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Wrapper = styled.div({
	display: 'flex',
	fontSize: '14px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const HiddenInput = styled.input({
	height: 0,
	width: 0,
	border: 'none',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':focus': {
		outline: 'none',
		border: 'none',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FlexContainer = styled.div({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'flex-end',
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'> button:first-child': {
		marginRight: token('space.075', '6px'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledIconContainer = styled.div({
	cursor: 'pointer',
	width: '18px',
	display: 'flex',
	justifyContent: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const BylineContainer = styled.div({
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const BylineMessageContainer = styled.span({
	marginRight: token('space.025', '2px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> span': {
		verticalAlign: 'middle',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const OwnerSwapBackground = styled.div({
	backgroundColor: token('elevation.surface', N10),
	marginTop: token('space.250', '20px'),
	padding: token('space.250', '20px'),
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-evenly',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const OwnerSwapIndividualContainer = styled.div({
	display: 'flex',
	maxWidth: '164px',
	justifyContent: 'center',
	alignSelf: 'flex-start',
	flex: '1.5',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const OwnerSwapIndividualTextContainer = styled.div({
	marginLeft: token('space.075', '6px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const OwnerSwapIndividualTitle = styled.div({
	fontSize: '11px',
	lineHeight: '14px',
	color: token('color.text.subtle', N200),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const OwnerSwapIndividualName = styled.div({
	fontSize: '14px',
	fontWeight: '500',
	lineHeight: '20px',
	color: token('color.text', N800),
	maxWidth: '123px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const PopupContainer = styled.div({
	padding: token('space.200', '16px'),
	textAlign: 'start',
	width: '360px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const PopupUserDetailsContainer = styled.div({
	padding: token('space.0', '0px'),
	color: token('color.text', '#172b4d'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const PopupButtonContainer = styled.div({
	marginTop: token('space.300', '24px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const OwnerSwapArrow = styled.div({
	padding: `${token('space.0', '0px')} ${token('space.200', '16px')}`,
	height: '24px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const OnboardingContainer = styled.span({
	position: 'relative',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledSpotlightCardWrapper = styled.div({
	position: 'absolute',
	zIndex: 701,
	// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage/preview
	left: '-133px',
	// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage/preview
	top: '30px',
	fontSize: '14px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CustomHeading = styled.div({
	color: token('color.text.inverse', N0),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const OwnerSwapArrowContainer = styled.div({
	height: '35px',
	display: 'flex',
	alignItems: 'center',
	alignSelf: 'flex-start',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ModalLabel = styled.p({
	color: token('color.text.subtle', N200),
	fontWeight: token('font.weight.semibold', '600'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.body.small', fontFallback.body.small),
});
