import gql from 'graphql-tag';

export const PersonFragment = gql`
	fragment PersonFragment on Person {
		displayName
		type
		... on KnownUser {
			accountId
		}
		... on User {
			accountId
		}
		profilePicture {
			path
		}
	}
`;
