import { useContext } from 'react';

import { getApolloClient } from '@confluence/graphql';
import { SPAViewContext } from '@confluence/spa-view-context';
import { ExperienceTrackerContext } from '@confluence/experience-tracker';

import { ContentOwnershipCheckRestrictionsQuery } from './queries/ContentOwnershipCheckRestrictionsQuery.graphql';
import type {
	ContentOwnershipCheckRestrictionsQuery as ContentOwnershipCheckRestrictionsQueryType,
	ContentOwnershipCheckRestrictionsQueryVariables,
} from './queries/__types__/ContentOwnershipCheckRestrictionsQuery';
import type { ContentOwnershipContributorsQuery as ContentOwnershipContributorsQueryType } from './queries/__types__/ContentOwnershipContributorsQuery';
import type { Restrictions, UserType } from './types';
import { i18n } from './i18n';

export const checkIsUserRestricted = async (
	contentId: string | undefined,
	newOwnerId: string,
): Promise<Restrictions> => {
	const result: Restrictions = {
		isUserRestricted: false,
		isSpaceRestricted: false,
		isParentPageRestricted: false,
		isEditPageRestricted: false,
	};

	if (!contentId) {
		return result;
	}

	const response = await getApolloClient().query<
		ContentOwnershipCheckRestrictionsQueryType,
		ContentOwnershipCheckRestrictionsQueryVariables
	>({
		query: ContentOwnershipCheckRestrictionsQuery,
		variables: {
			contentId,
			accountId: newOwnerId,
		},
	});

	const restrictions = response?.data?.userWithContentRestrictions;

	if (!restrictions) {
		// TODO: Could this happen? What should we do?
		return result;
	}

	const operations = restrictions?.operations ?? [];

	const canEdit = operations.some(
		(operationCheckResult) => operationCheckResult?.operation === 'update',
	);

	if (!canEdit) {
		// user is restricted on some level
		result.isUserRestricted = true;
		result.isEditPageRestricted = true;
	}

	// if there are space or parent page restrictions,
	// handle updating the warning state correctly
	if (!restrictions.hasSpaceEditPermission || restrictions.restrictingContent?.id) {
		// user is restricted on some level
		result.isUserRestricted = true;

		// check if user can edit in the space
		if (!restrictions.hasSpaceEditPermission) {
			result.isSpaceRestricted = true;
		}
		// check if the user is restricted on any parent content
		if (restrictions.restrictingContent?.id) {
			result.isParentPageRestricted = true;
		}
	}

	return result;
};

const toUserProps = (graphqlUser) => ({
	id: graphqlUser?.accountId,
	name: graphqlUser?.displayName,
	avatarUrl: graphqlUser?.profilePicture?.path,
});

export const parseContributors = (data: ContentOwnershipContributorsQueryType): UserType[] => {
	const content = data?.content?.nodes?.[0];
	const contributors = data?.contentContributors?.nodes?.map(toUserProps) || [];
	const lastModifier = toUserProps(content?.version?.by);
	const owner = toUserProps(content?.history?.ownedBy);

	return [
		// Owner is always shown first
		owner,
		// Then the last person to have edited, unless they're also the author
		...(lastModifier.id === owner.id ? [] : [lastModifier]),
		// Then all the other contributors
		...contributors.filter(({ id }) => id !== owner.id && id !== lastModifier.id),
	];
};

export const useSPAViewContext = () => {
	return useContext(SPAViewContext);
};

export const useExperienceTrackerContext = () => {
	return useContext(ExperienceTrackerContext);
};

export const generateMailtoLink = ({
	intl,
	spaceAdmins,
	spacePermissionsUrl,
	spaceKey,
	contentId,
	requestedUser,
	currentUser,
	pageTitle,
}) => {
	const spaceAdminsEmailList = spaceAdmins
		?.filter((admin) => admin.email)
		?.map((admin) => admin.email)
		?.join(',');

	const subject = intl.formatMessage(i18n.requestSpacePermissionAdminEmailSubject);

	const emailBodyProps = {
		spacePermissionsUrl: `${window.location.origin}${spacePermissionsUrl}`,
		requestedUser,
		currentUser,
		pageTitle,
		pageUrl: `${window.location.origin}/spaces/${spaceKey}/pages/${contentId}`,
		spaceName: spaceKey,
	};

	const body = intl.formatMessage(i18n.requestSpacePermissionAdminEmailBody, emailBodyProps);

	return `mailto:${spaceAdminsEmailList}?subject=${subject}&body=${body}`;
};
