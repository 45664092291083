import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

import { ContentOwnershipDialog } from './ContentOwnershipDialog';

type ContentOwnershipContextType = {
	isModalOpen: boolean;
	openModal: () => void;
	closeModal: () => void;
};
const ContentOwnershipContext = createContext<ContentOwnershipContextType>({
	isModalOpen: false,
	openModal: () => {
		/* no-op */
	},
	closeModal: () => {
		/* no-op */
	},
});

type ContentOwnershipContextProviderProps = {
	children: React.ReactNode;
};
export const ContentOwnershipContextProvider = ({
	children,
}: ContentOwnershipContextProviderProps) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const openModal = useCallback(() => setIsModalOpen(true), []);
	const closeModal = useCallback(() => setIsModalOpen(false), []);

	const contentOwnershipContextValue = useMemo(
		() => ({
			isModalOpen,
			openModal,
			closeModal,
		}),
		[isModalOpen, openModal, closeModal],
	);

	return (
		<ContentOwnershipContext.Provider value={contentOwnershipContextValue}>
			<>
				{children}
				<ContentOwnershipDialog />
			</>
		</ContentOwnershipContext.Provider>
	);
};

export const useContentOwnershipContext = () => {
	return useContext(ContentOwnershipContext);
};

export const ContentOwnershipContextConsumer = ({ children }) => {
	const context = useContentOwnershipContext();
	return children(context);
};
