import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { usePageSpaceKey } from '@confluence/page-context';

import { SpaceAdminQuery } from './queries/SpaceAdminQuery.graphql';
import type {
	SpaceAdminQuery as SpaceAdminQueryType,
	SpaceAdminQueryVariables,
} from './queries/__types__/SpaceAdminQuery';
import { useSPAViewContext } from './content-ownership-helpers';

export const useIsSpaceOrSiteAdmin = () => {
	const [spaceKey] = usePageSpaceKey();
	const { isSiteAdmin } = useSPAViewContext();
	const [isSpaceAdmin, setIsSpaceAdmin] = useState<boolean>(false);

	const { data } = useQuery<SpaceAdminQueryType, SpaceAdminQueryVariables>(SpaceAdminQuery, {
		variables: {
			spaceKey: spaceKey!,
		},
		skip: !spaceKey,
	});

	useEffect(() => {
		const isAdmin = data?.space?.currentUser?.isAdmin ?? false;
		setIsSpaceAdmin(isAdmin);
	}, [data]);

	return isSiteAdmin || isSpaceAdmin;
};
