import gql from 'graphql-tag';

import { PersonFragment } from './PersonFragment.fragment';

export const UpdateOwnerMutation = gql`
	mutation UpdateOwnerMutation($contentId: ID!, $ownerId: String!) {
		updateOwner(input: { contentId: $contentId, ownerId: $ownerId }) {
			content {
				id
				history {
					ownedBy {
						...PersonFragment
					}
					lastOwnedBy {
						...PersonFragment
					}
				}
			}
		}
	}

	${PersonFragment}
`;
