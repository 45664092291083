import gql from 'graphql-tag';

export const ContentOwnershipCheckRestrictionsQuery = gql`
	query ContentOwnershipCheckRestrictionsQuery($accountId: String!, $contentId: ID!) {
		userWithContentRestrictions(accountId: $accountId, contentId: $contentId) {
			accountId
			hasSpaceEditPermission
			hasSpaceViewPermission
			restrictingContent {
				id
				links {
					webui
					base
				}
			}
			operations {
				operation
			}
		}
	}
`;
