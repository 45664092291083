export const PAGE_OWNERSHIP_EP_MESSAGE = 'confluence_page_ownership_changeboarding';
export const CONTENT_OWNERSHIP_WEB_ITEM_ID = 'change-page-ownership-link';

export const DEFAULT_CONTRIBUTORS_FETCH_LIMIT = 10;
export const EXPECTED_ERROR_MESSAGES = {
	USER_IS_NULL: 'You cannot set an owner as an anonymous user.',
	USER_NOT_FOUND: 'Owner id is an invalid account id.',
	USER_MISSING_PAGE_PERMISSION: 'New owner must have view and edit permission on the content.',
	CONTENT_ID_INVALID: 'Content id is invalid.',
	INSUFFICIENT_PERMISSIONS:
		'You must be the content creator, owner, or a space admin to set the owner.',
};
export const PARENT_PAGE_LEARN_MORE_URL =
	'https://support.atlassian.com/confluence-cloud/docs/add-or-remove-page-restrictions/';
export const CHANGEBOARDING_LEARN_MORE_LINK =
	'https://support.atlassian.com/confluence-cloud/docs/transfer-ownership-of-your-page-to-another-person/';
export const CONFIRM_MODAL_LEARN_MORE_LINK =
	'https://support.atlassian.com/confluence-cloud/docs/transfer-ownership-of-your-page-to-another-person/';
