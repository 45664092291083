import { PermissionDisplayType } from '../graphql/__types__/SpacePermissionsPrincipalsMutation';

// Make sure to update the regex in the route if you change these
export enum SpacePermissionsTabKey {
	Groups = 'groups',
	Users = 'users',
	Guests = 'guests',
	AnonymousAccess = 'anonymous',
	PublicLinks = 'public-links',
}

export const SPACE_PERMISSION_TAB_KEY_PRINCIPAL_MAP = {
	[SpacePermissionsTabKey.Groups]: PermissionDisplayType.GROUP,
	[SpacePermissionsTabKey.Users]: PermissionDisplayType.LICENSED_USER,
	[SpacePermissionsTabKey.Guests]: PermissionDisplayType.GUEST_USER,
	[SpacePermissionsTabKey.AnonymousAccess]: PermissionDisplayType.ANONYMOUS,
};
