// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Container } from 'unstated';

export class RestrictionsDialogStateContainer extends Container<any> {
	state: {
		contentId: string;
		isOpen: boolean;
		triggeredFrom: string | null;
		hideRestrictionsButtonSpinner: boolean;
	} = {
		isOpen: false,
		triggeredFrom: null,
		hideRestrictionsButtonSpinner: false,
		contentId: '',
	};

	initState = () => {
		return this.setState({
			contentId: '',
			isOpen: false,
			triggeredFrom: null,
			hideRestrictionsButtonSpinner: false,
		});
	};

	getContentId() {
		return this.state.contentId;
	}

	getIsOpen() {
		return this.state.isOpen;
	}

	getTriggeredFrom() {
		return this.state.triggeredFrom;
	}

	setHideRestrictionsButtonSpinner = () => {
		return this.setState({ hideRestrictionsButtonSpinner: true });
	};

	getHideRestrictionsButtonSpinner() {
		return this.state.hideRestrictionsButtonSpinner;
	}

	setIsOpen(isOpen: boolean, triggeredFrom: string | null = null, callback?: () => void) {
		return this.setState({ isOpen, triggeredFrom }, () => {
			if (callback) {
				// using setTimeout here to let dialog transitions finish in case we want to interact with the UI. needed to trigger the share button.
				setTimeout(callback, 0);
			}
		});
	}

	setContentId(contentId: string) {
		return this.setState({ contentId });
	}
}
